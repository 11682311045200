window.onload = function () {
  window.ui = SwaggerUIBundle({
    urls: [
      {
        name: 'EdgeTag API',
        url: 'https://api.edgetag.io/v1/swagger.json',
      },
    ],
    dom_id: '#swagger-ui',
    deepLinking: true,
    presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
    plugins: [SwaggerUIBundle.plugins.DownloadUrl],
    layout: 'StandaloneLayout',
  })
}
